$(document).ready(function () {
  // カテゴリページの絞り込み
  $(".js-select-subcategory").on("click", function(){
    const id = $(this).data("id")

    $(".js-select-subcategory").removeClass("text-white").removeClass("bg-onescene-blue")
    $(this).addClass("text-white").addClass("bg-onescene-blue")

    if(id) {
      $(`.js-subcategory`).hide()
      $(`.js-subcategory[data-id="${id}"]`).fadeIn()
    } else {
      $(`.js-subcategory`).fadeIn()
    }
  })
})
