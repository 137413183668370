$(document).ready(function() {
  if ($(".js-product-photo-swiper").length == 1) {
    const swiper = $(".js-product-photo-swiper").get(0).swiper
    swiper.on('slideChange', function (swiper) {
      $(".js-product-photo").removeClass("!border-red-500")

      const element = $(`.js-product-photo[data-index="${swiper.activeIndex}"]`)
      element.addClass("!border-red-500")
      $("#js-product-photo-caption").text(element.data("caption"))
    });

    $(".js-product-photo").on("click", function(){
      swiper.slideTo($(this).data("index"))
    })
  }
})
