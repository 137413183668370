$(document).ready(function () {
  // 新しいクレジットカード画面へ遷移
  $("#js-buy-redirect-to-credit-card").on("click", function () {
    const formElement = $("#js-buy-redirect-to-credit-card-form")
    formElement.submit()
  })

  // 新しい住所画面へ遷移
  $("#js-buy-redirect-to-address").on("click", function () {
    const formElement = $("#js-buy-redirect-to-address-form")
    formElement.submit()
  })
})
