$(document).ready(function() {
  if ($("#js-banners").length > 0) {
    const swiper = new Swiper("#js-banners", {
      loop: true,
      slidesPerView: "auto",
      centeredSlides: true,
      spaceBetween: 16,
      navigation: {
        nextEl: '.js-banner-next',
        prevEl: '.js-banner-prev',
      },
    });
  }

  if ($("#js-items").length > 0) {
    const swiper = new Swiper("#js-items", {
      slidesPerView: "auto",
      spaceBetween: 16,
      navigation: {
        nextEl: '.js-item-next',
        prevEl: '.js-item-prev',
      },
    });
  }

  if ($("#js-products").length > 0) {
    const swiper = new Swiper("#js-products", {
      slidesPerView: "auto",
      spaceBetween: 16,
      navigation: {
        nextEl: '.js-product-next',
        prevEl: '.js-product-prev',
      },
    });
  }

  if ($("#js-stories").length > 0) {
    const swiper = new Swiper("#js-stories", {
      slidesPerView: "auto",
      spaceBetween: 16,
      navigation: {
        nextEl: '.js-story-next',
        prevEl: '.js-story-prev',
      },
    });
  }

  if ($("#js-articles").length > 0) {
    const swiper = new Swiper("#js-articles", {
      slidesPerView: "auto",
      spaceBetween: 16,
      navigation: {
        nextEl: '.js-article-next',
        prevEl: '.js-article-prev',
      },
    });
  }
})
