$(document).ready(function () {
  // ヘッダーメニュー表示
  $(".js-toggle-header-menu").on("click", function () {
    $("#js-header-menu").toggle()
  })

  $(".js-toggle-header-search").on("click", function () {
    $("#js-header-search").toggleClass("hidden")
  })

  if($("#js-header-notification").length > 0 || $("#js-header-notification").length > 0) {
    setTimeout(function () {
      $.ajax("/api/information/header", {
        type: "get",
        dataType: "script",
      })
    }, 300)
  }

  var searchTimer;
  var waitTime = 300;
  var val;
  $("#js-header-search-form").on("keyup", function () {
    if (val != $(this).val()) {
      clearTimeout(searchTimer);
      val = $(this).val();

      if (val.length > 0){
        searchTimer = setTimeout(function () {
          $.ajax("/api/products/header", {
            type: "get",
            data: { q: val, time: (new Date()).getTime() },
            dataType: "script",
          })
        }, waitTime)
      } else {
        $("#js-header-search-results").remove()
      }
    }
  })
})
