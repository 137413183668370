import MicroModal from 'micromodal';
MicroModal._show = MicroModal.show
MicroModal.show = function(targetModal, config){ MicroModal._show(targetModal, {disableScroll: true, ...config}) }
import 'lazysizes';

require("./common/counter")
require("./common/date_field")
require("./common/scroll_table")

require("./main/address")
require("./main/bank")
require("./main/buy")
require("./main/category")
require("./main/credit_card")
require("./main/header")
require("./main/image_file_field")
require("./main/product_photo")
require("./main/product")
require("./main/request")
require("./main/sell")
require("./main/swiper")
require("./main/tab")
require("./main/top")
require("./main/trade")

// swiperより後ろにする
require("./main/product_photo_swiper")

