$(document).ready(function () {
  if ($(".js-date-field").length > 0) {
    $(".js-date-field").each(function () {
      const token = $(this).data("token");

      $(`[name='_year_${token}'], [name='_month_${token}'], [name='_day_${token}']`).on("change", function () {
        const year = $(`[name='_year_${token}']`).val()
        const month = $(`[name='_month_${token}']`).val()
        const day = $(`[name='_day_${token}']`).val()

        if (year.match(/^\d{4}$/) && month.match(/^\d{1,2}$/) && day.match(/^\d{1,2}$/)) {
          $(`.js-date-field[data-token="${token}"]`).val(`${year}-${month}-${day}`)
        } else {
          $(`.js-date-field[data-token="${token}"]`).val("")
        }
      })
    })
  }
})
