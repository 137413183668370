const cardValidator = require("card-validator");

$(document).ready(function () {
  // カード一覧でのブランド表示
  $(".js-credit-card").each(function(){
    const result = cardValidator.number($(this).find(".js-credit-card-number").text().substr(0, 6))

    if (result.card) {
      $(this).find(`.js-credit-card-logo img[data-brand="${result.card.type}"]`).fadeIn()
    }
  })

  // カード一覧での削除時の確認モーダル表示
  $(".js-delete-credit-card-button").on("click", function () {
    $("#js-delete-credit-card").html($(`.js-credit-card[data-index='${$(this).data("index")}']`).clone())
    $("#js-delete-credit-card-form").attr("action", $(this).data("url"))
    MicroModal.show("js-modal")
  })

  // 番号 validation
  const checkCardNumber = function (cardNumber, checkIsValid = false) {
    const result = cardValidator.number(cardNumber)

    $("#js-credit-card-cvv-field").attr("maxlength", 4)

    if (result.card) {
      $(`.js-credit-card-logo img[data-brand="${result.card.type}"]`).fadeIn()
      $(`.js-credit-card-logo img:not([data-brand="${result.card.type}"])`).hide()

      if (result.card.code && result.card.code.size) {
        $("#js-credit-card-cvv-field").attr("maxlength", result.card.code.size)
      }
    } else {
      $(".js-credit-card-logo img").hide()
    }

    if (!result.isPotentiallyValid) {
      $("#js-credit-card-number-errors").empty()
      $("#js-credit-card-number-errors").append("<p>番号が正しくありません。</p>").fadeIn()
      return false
    } else if (checkIsValid && !result.isValid) {
      $("#js-credit-card-number-errors").empty()
      $("#js-credit-card-number-errors").append("<p>番号が正しくありません。</p>").fadeIn()
      return false
    } else {
      if (result.card && (result.card.type !== "visa" && result.card.type !== "mastercard")) {
        $("#js-credit-card-number-errors").empty()
        $("#js-credit-card-number-errors").append("<p>利用できないカードブランドです。</p>").fadeIn()
        return false
      } else {
        $("#js-credit-card-number-errors").empty()
        $("#js-credit-card-number-errors").hide()
        return true
      }
    }
  }

  // 名義人 validation
  const checkCardholderName = function (name) {
    if (!name.match(/^[A-Z ]+$/)) {
      $("#js-credit-cardholder-name-errors").empty()
      $("#js-credit-cardholder-name-errors").append("<p>名義人は半角大文字アルファベット、半角スペースで入力してください。</p>").fadeIn()
      return false
    } else if (name.length < 2) {
      $("#js-credit-cardholder-name-errors").empty()
      $("#js-credit-cardholder-name-errors").append("<p>名義人は2文字以上で入力してください。</p>").fadeIn()
      return false
    } else if (name.length > 45) {
      $("#js-credit-cardholder-name-errors").empty()
      $("#js-credit-cardholder-name-errors").append("<p>名義人は45文字以下で入力してください。</p>").fadeIn()
      return false
    } else {
      $("#js-credit-cardholder-name-errors").empty()
      $("#js-credit-cardholder-name-errors").hide()
      return true
    }
  }

  // 月 validation
  const checkMonth = function (month, checkIsValid = false) {
    const result = cardValidator.expirationMonth(month)

    if (!result.isPotentiallyValid) {
      $("#js-credit-card-exp-month-errors").empty()
      $("#js-credit-card-exp-month-errors").append("<p>月が正しくありません。</p>").fadeIn()
      return false
    } else if (checkIsValid && !result.isValid) {
      $("#js-credit-card-exp-month-errors").empty()
      $("#js-credit-card-exp-month-errors").append("<p>月が正しくありません。</p>").fadeIn()
      return false
    } else {
      $("#js-credit-card-exp-month-errors").empty()
      $("#js-credit-card-exp-month-errors").hide()
      return true
    }
  }

  // 年 validation
  const checkYear = function (year, checkIsValid = false) {
    const result = cardValidator.expirationYear(year)

    if (!result.isPotentiallyValid) {
      $("#js-credit-card-exp-year-errors").empty()
      $("#js-credit-card-exp-year-errors").append("<p>年が正しくありません。</p>").fadeIn()
      return false
    } else if (checkIsValid && !result.isValid) {
      $("#js-credit-card-exp-year-errors").empty()
      $("#js-credit-card-exp-year-errors").append("<p>年が正しくありません。</p>").fadeIn()
      return false
    } else {
      $("#js-credit-card-exp-year-errors").empty()
      $("#js-credit-card-exp-year-errors").hide()
      return true
    }
  }

  // CVV validation
  const checkCvv = function (cvv, checkIsValid = false) {
    const result = cardValidator.cvv(cvv)

    if (!result.isPotentiallyValid) {
      $("#js-credit-card-cvv-errors").empty()
      $("#js-credit-card-cvv-errors").append("<p>CVVが正しくありません。</p>").fadeIn()
      return false
    } else if (checkIsValid && !result.isValid) {
      $("#js-credit-card-cvv-errors").empty()
      $("#js-credit-card-cvv-errors").append("<p>CVVが正しくありません。</p>").fadeIn()
      return false
    } else {
      $("#js-credit-card-cvv-errors").empty()
      $("#js-credit-card-cvv-errors").hide()
      return true
    }
  }

  // 番号入力途中のvalidation処理
  $("#js-credit-card-number-field").on("keyup", function () {
    const cardNumber = $(this).val()
    checkCardNumber(cardNumber)
  })

  // 名義人入力途中のvalidation処理
  $("#js-credit-cardholder-name-field").on("keyup", function () {
    const cardholderName = $(this).val()
    checkCardholderName(cardholderName)
  })

  // 月入力途中のvalidation処理
  $("#js-credit-card-exp-month-field").on("keyup", function () {
    const month = $(this).val()
    checkMonth(month)
  })

  // 年入力途中のvalidation処理
  $("#js-credit-card-exp-year-field").on("keyup", function () {
    const year = $(this).val()
    checkYear(year)
  })

  // カード追加ボタン押下時の処理
  $("#js-credit-card-form-submit").on("click", function () {
    const cardNumber = $("#js-credit-card-number-field").val()
    const cardholderName = $("#js-credit-cardholder-name-field").val()
    const month = $("#js-credit-card-exp-month-field").val()
    const year = $("#js-credit-card-exp-year-field").val()
    const cvv = $("#js-credit-card-cvv-field").val()
    $("#js-credit-card-errors").hide()

    if (checkCardNumber(cardNumber, true) & checkCardholderName(cardholderName) & checkMonth(month, true) & checkYear(year, true) & checkCvv(cvv, true)) {
      const data = {}

      data.token_api_key = $(this).data("token")
      data.card_number = cardNumber
      data.card_expire = `${month}/${year}`
      data.security_code = cvv
      data.cardholder_name = cardholderName
      data.lang = "ja"

      const xhr = new XMLHttpRequest()
      xhr.open('POST', "https://api.veritrans.co.jp/4gtoken", true)
      xhr.setRequestHeader('Accept', 'application/json')
      xhr.setRequestHeader('Content-Type', 'application/json; charset=utf-8')
      xhr.addEventListener('loadend', function () {
        if (xhr.status === 0) {
          alert("決済サーバーとの接続に失敗しました。");
          return;
        }

        const response = JSON.parse(xhr.response);
        if (xhr.status == 200) {
          $("#js-credit-card-number-field").attr("disabled", true)
          $("#js-credit-cardholder-name-field").attr("disabled", true)
          $("#js-credit-card-exp-month-field").attr("disabled", true)
          $("#js-credit-card-exp-year-field").attr("disabled", true)
          $("#js-credit-card-cvv-field").attr("disabled", true)
          $("#js-credit-card-token-field").val(response.token)
          $("#js-credit-card-form").submit()
        } else {
          $("#js-credit-card-errors").empty()
          $("#js-credit-card-errors").append(`<p>${response.message}</p>`).fadeIn()
        }
      })

      xhr.send(JSON.stringify(data))
    }

    return false
  })
})
