$(document).ready(function () {
  // 画像ファイルアップのサムネ表示
  $(".js-image-file-field").on("change", function () {
    const target = $(this).data("target")

    if (this.files && this.files[0]) {
      const reader = new FileReader();
      reader.onload = function (e) {
        $(target).attr("src", e.target.result)
        $(target).fadeIn()
      };
      reader.readAsDataURL(this.files[0]);
    } else {
      $(target).attr("src", null)
      $(target).hide()
    }
  })
})
