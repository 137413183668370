$(document).ready(function () {
  const counter = function (hash, count) {
    $(`.js-counter-disp[data-counter="${hash}"]`).text(count)
  }

  $(".js-counter").each(function () {
    $(this).on("keyup", function () {
      counter($(this).data("counter"), $(this).val().replace(/\r|\n/g, "").length)
    })
    counter($(this).data("counter"), $(this).val().replace(/\r|\n/g, "").length)
  })
})
