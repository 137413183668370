$(document).ready(function () {
  $(".js-scrollable").each(function(){
    if(this.scrollWidth > this.clientWidth) {
      $(this).on("click scroll", function(){
        $(this).off("click scroll")
        $(this).find(".js-scrollable-text").remove()
      })
    } else {
      $(this).find(".js-scrollable-text").remove()
    }
  })
})
