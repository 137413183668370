$(document).ready(function () {
  // 支店コード検索を開始したとき
  $("#js-open-branch-search").on("click", function(){
    $("#js-branch-notice").hide()
    $("#js-branch-form").hide()
    const bankCode = $("#js-bank-code-field").val()
    if (bankCode) {
      $("#js-branch-form").show()
      $("#js-bank-code-hidden-field").val(bankCode)
    } else {
      $("#js-branch-notice").show()
    }

    MicroModal.show("js-branch-code-modal")
  })

  // 銀行コード検索で銀行をクリックしたとき
  $("body").on("click", ".js-bank-data", function () {
    $("#js-bank-code-field").val($(this).data("code"))
    $("#js-bank-name-field").val($(this).data("name"))
    MicroModal.close()
  })

  // 支店コード検索で支店をクリックしたとき
  $("body").on("click", ".js-branch-data", function () {
    $("#js-branch-code-field").val($(this).data("code"))
    $("#js-branch-name-field").val($(this).data("name"))
    MicroModal.close()
  })
})
